 
 <div class="topBar desktop">
    <div class="buttons">
        <!-- <div #openDeskButton>
            <fa-icon [icon]="['fas', 'bars']" size="2x" class="menuIcon"></fa-icon>
        </div> -->

        <a routerLink="/info" class="infoButton">INFO</a>
        <!-- To keep horizLine at same position as other header (space of PD-logo) -->
        <div class="invisibleSpace"></div>
        <a href="https://tickets.parsidance.be" class="ticketButton" *ngIf="showTicketSale" (click)="redirectToTickets()">TICKETS</a>
    </div>
    <hr class="topBar"/>
</div>
 
<div class="topBar mobile">
    <div class="buttons">
        <a routerLink="/info" class="infoButton">INFO</a>
        <div class="invisibleSpace"></div>
        <!-- <div #openMobButton class="openMobButton">
            <fa-icon [icon]="['fas', 'bars']" size="2x" class="menuIcon"></fa-icon>
        </div> -->
        <a href="https://tickets.parsidance.be" class="ticketButton" *ngIf="showTicketSale" (click)="redirectToTickets()">TICKETS</a>
        <!-- To keep horizLine at same position as other header (space of PD-logo) -->
    </div>
    <hr class="topBar"/>
</div>

<div class="topBarClose mobile">
    <div class="closeContainer">
        <div class="xIcon mobile" #closeMobButton>
            <fa-icon [icon]="['fas', 'xmark']" size="2x" class="xIcon"></fa-icon>   
        </div>
    </div>
    <hr/>
</div>

<div class="parsidanceLogo">
    <a routerLink="/">
        <img class="parsidanceLogo" src="../../assets/svg/Parsidance.svg">
    </a>
    <p class="subtitleLogo">Friday 18.04.2025 - Saturday 19.04.2025</p>
</div>

<!-- <div id="menu" #menuDiv>
    <div class="xIcon" #closeDeskButton >
        <fa-icon [icon]="['fas', 'xmark']" size="2x" class="xIcon"></fa-icon>    
    </div>
    <ul>
        <li><a routerLink="info">INFO</a></li>
        <li><a href="line-up/a-z">LINE-UP</a></li>
        <li><a href="metdankaan">MET DANK AAN</a></li>
    </ul>  
    
    <div class="trademarkText">
        <a routerLink="/">&copy; Parsidance Festival 2025</a>
    </div>
</div> -->