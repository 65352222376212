<app-header></app-header>

<div class="selectTab">
    <h1>INFO</h1>
</div>

<!-- https://material.angular.io/cdk/accordion/overview -->
<h2 class="infoTitle">Tickets</h2>
<cdk-accordion class="accordion">
    <ng-container *ngFor="let title of titelTickets; let index = index">
        <cdk-accordion-item 
            #accordionItem="cdkAccordionItem" 
            class="accordion-item" 
            role="button"
            tabindex="0" 
            [attr.id]="'accordion-header-' + index" 
            [attr.aria-expanded]="accordionItem.expanded" 
            [attr.aria-controls]="'accordion-body-' + index">

            <div 
                class="accordion-item-header" 
                (click)="accordionItem.toggle()" 
                [style.font-style]="accordionItem.expanded ? 'italic' : 'normal'"
                >
                    {{ titelTickets[index] }}

                <span class="accordion-item-expand">
                    <fa-icon *ngIf="accordionItem.expanded" [icon]="['fas', 'chevron-up']" size="1x" style="color: white"></fa-icon>
                    <fa-icon *ngIf="!accordionItem.expanded"[icon]="['fas', 'chevron-down']" size="1x" style="color: white"></fa-icon>
                </span>
            </div>

            <div
                class="accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index"
                [innerHTML]="uitlegTickets[index]">
            </div>
        </cdk-accordion-item>
    </ng-container>
</cdk-accordion>

<h2 class="infoTitle">Algemeen</h2>
<cdk-accordion class="accordion">
    <ng-container *ngFor="let title of titelAlgemeen; let index = index">
        <cdk-accordion-item 
            #accordionItem="cdkAccordionItem" 
            class="accordion-item" 
            role="button"
            tabindex="0" 
            [attr.id]="'accordion-header-' + index" 
            [attr.aria-expanded]="accordionItem.expanded" 
            [attr.aria-controls]="'accordion-body-' + index">

            <div 
                class="accordion-item-header" 
                (click)="accordionItem.toggle()" 
                [style.font-style]="accordionItem.expanded ? 'italic' : 'normal'"
                >
                    {{ titelAlgemeen[index] }}

                <span class="accordion-item-expand">
                    <fa-icon *ngIf="accordionItem.expanded" [icon]="['fas', 'chevron-up']" size="1x" style="color: white"></fa-icon>
                    <fa-icon *ngIf="!accordionItem.expanded"[icon]="['fas', 'chevron-down']" size="1x" style="color: white"></fa-icon>
                </span>
            </div>

            <div
                class="accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index" 
                [innerHTML]="uitlegAlgemeen[index]">
            </div>
        </cdk-accordion-item>
    </ng-container>
</cdk-accordion>

<!-- 
<h2 class="infoTitle">Mobiliteit</h2>
<cdk-accordion class="accordion">
    <ng-container *ngFor="let title of titelMobiliteit; let index = index">
        <cdk-accordion-item 
            #accordionItem="cdkAccordionItem" 
            class="accordion-item" 
            role="button"
            tabindex="0" 
            [attr.id]="'accordion-header-' + index" 
            [attr.aria-expanded]="accordionItem.expanded" 
            [attr.aria-controls]="'accordion-body-' + index">

            <div 
                class="accordion-item-header" 
                (click)="accordionItem.toggle()" 
                [style.font-style]="accordionItem.expanded ? 'italic' : 'normal'"
                >
                    {{ titelMobiliteit[index] }}

                <span class="accordion-item-expand">
                    <fa-icon *ngIf="accordionItem.expanded" [icon]="['fas', 'chevron-up']" size="1x" style="color: white"></fa-icon>
                    <fa-icon *ngIf="!accordionItem.expanded"[icon]="['fas', 'chevron-down']" size="1x" style="color: white"></fa-icon>
                </span>
            </div>

            <div
                class="accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index"
                [innerHTML]="uitlegMobiliteit[index]">
            </div>
        </cdk-accordion-item>
    </ng-container>
</cdk-accordion>

<h2 class="infoTitle">Herbruikbare Bekers</h2>
<cdk-accordion class="accordion">
    <ng-container *ngFor="let title of titelHerbruikbareBekers; let index = index">
        <cdk-accordion-item 
            #accordionItem="cdkAccordionItem" 
            class="accordion-item" 
            role="button"
            tabindex="0" 
            [attr.id]="'accordion-header-' + index" 
            [attr.aria-expanded]="accordionItem.expanded" 
            [attr.aria-controls]="'accordion-body-' + index">

            <div 
                class="accordion-item-header" 
                (click)="accordionItem.toggle()" 
                [style.font-style]="accordionItem.expanded ? 'italic' : 'normal'"
                >
                    {{ titelHerbruikbareBekers[index] }}

                <span class="accordion-item-expand">
                    <fa-icon *ngIf="accordionItem.expanded" [icon]="['fas', 'chevron-up']" size="1x" style="color: white"></fa-icon>
                    <fa-icon *ngIf="!accordionItem.expanded"[icon]="['fas', 'chevron-down']" size="1x" style="color: white"></fa-icon>
                </span>
            </div>

            <div
                class="accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index"
                [innerHTML]="uitlegHerbruikbareBekers[index]">
            </div>
        </cdk-accordion-item>
    </ng-container>
</cdk-accordion>

<h2 class="infoTitle">Food & Drinks</h2>
<cdk-accordion class="accordion">
    <ng-container *ngFor="let title of titelFoodDrinks; let index = index">
        <cdk-accordion-item 
            #accordionItem="cdkAccordionItem" 
            class="accordion-item" 
            role="button"
            tabindex="0" 
            [attr.id]="'accordion-header-' + index" 
            [attr.aria-expanded]="accordionItem.expanded" 
            [attr.aria-controls]="'accordion-body-' + index">

            <div 
                class="accordion-item-header" 
                (click)="accordionItem.toggle()" 
                [style.font-style]="accordionItem.expanded ? 'italic' : 'normal'"
                >
                    {{ titelFoodDrinks[index] }}

                <span class="accordion-item-expand">
                    <fa-icon *ngIf="accordionItem.expanded" [icon]="['fas', 'chevron-up']" size="1x" style="color: white"></fa-icon>
                    <fa-icon *ngIf="!accordionItem.expanded"[icon]="['fas', 'chevron-down']" size="1x" style="color: white"></fa-icon>
                </span>
            </div>

            <div
                class="accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index" 
                [innerHTML]="uitlegFoodDrinks[index]">
            </div>
        </cdk-accordion-item>
    </ng-container>
</cdk-accordion> -->
<app-footer></app-footer>