import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { timestamp } from 'rxjs';
import { ApiService } from '../api.service';

declare var FlipDown: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent {
  constructor(
    private scroller: ViewportScroller,
    private apiService: ApiService
    ) {}

  ticketSale = new Date("2024-11-23T10:00:00")
  ticketSaleTime = this.ticketSale.getTime() / 1000;
  showTicketSale = true;

  responseJson: any = {};
  ticketsSoldFriday: number = 0;
  ticketsSoldSaturday: number = 0;
  ticketsSoldCombi: number = 0;

  @ViewChild('fridayLabel')  fridayLabel!: ElementRef;
  @ViewChild('saturdayLabel')  saturdayLabel!: ElementRef;



  // ! TICKET COUNTDOWN
  ngAfterViewInit(): void {
    new Date().getTime
    new FlipDown(this.ticketSaleTime, 
      {theme: "darkSelfMade",}).start();
  }


  goToAftermovie() {
    this.scroller.scrollToAnchor("aftermovie");
  }


  // ! TICKETS PROGRESS BAR
  @Input() progressFriday: number = 0;
  @Input() progressSaturday: number = 0;


  ngOnInit() {
    this.apiService.getSoldTickets().subscribe(
      (response) => {
        this.responseJson = response;
        let ticketsSoldFriday = Number(this.responseJson['friday']);
        let ticketsSoldFridayCombi = Number(this.responseJson['fridayCombi']);
        let ticketsSoldSaturday = Number(this.responseJson['saturday']);
        let ticketsSoldSaturdayCombi = Number(this.responseJson['saturdayCombi']);
        this.animateProgressBar(ticketsSoldFriday, ticketsSoldSaturday, ticketsSoldFridayCombi, ticketsSoldSaturdayCombi);
      },
      (error) => {
        console.error('API Error:', error);
        this.responseJson = 'Error occurred during API call.';
      }
    );    
  }

  redirectToTickets(): void {
    window.open('https://tickets.parsidance.be/', '_blank');
  }

  animateProgressBar(ticketsSoldFriday: number, ticketsSoldSaturday: number, ticketsSoldFridayCombi: number, ticketsSoldSaturdayCombi: number) {
    let percentageFriday = Math.round((ticketsSoldFriday + ticketsSoldFridayCombi) / 2250 * 100);
    let percentageSaturday = Math.round((ticketsSoldSaturday + ticketsSoldSaturdayCombi) / 4250 * 100);

    setTimeout(() => {
      this.progressFriday = percentageFriday;
    }, 1000);
    setTimeout(() => {
      this.progressSaturday = percentageSaturday;
    }, 1000);

    if(percentageFriday >= 75) {
      if(document.getElementById("fridayLabel")){
        const saturdayLabel = document.getElementById("saturdayLabel");
        if (saturdayLabel) {
          saturdayLabel.style.color = "black";
        }
      }
    }

    if(percentageSaturday >= 75) {
      if(document.getElementById("saturdayLabel")){
        const saturdayLabel = document.getElementById("saturdayLabel");
        if (saturdayLabel) {
          saturdayLabel.style.color = "black";
        }
      }
    }
  }
}
