<footer>
    <hr class="footer">
    <div class="footer">
        <div class="socials">
            <h2 class="socials">FOLLOW US ON</h2>
            <div class="socialsIcons">
                <a href="https://www.facebook.com/Parsidance" target="_blank">
                    <fa-icon [icon]="['fab', 'facebook']" size="2x" style="color: white" class="socialIcon"></fa-icon>
                </a>
                <a href="https://www.instagram.com/parsidance_festival" target="_blank">
                    <fa-icon [icon]="['fab', 'instagram']" size="2x" style="color: white" class="socialIcon"></fa-icon> 
                </a>
                <a href="https://www.tiktok.com/@parsidance_festival" target="_blank">
                    <fa-icon [icon]="['fab', 'tiktok']" size="2x" style="color: white" class="socialIcon"></fa-icon> 
                </a>
                
                <a href="https://www.youtube.com/@scoutsparsifalrumbeke" target="_blank">
                    <fa-icon [icon]="['fab', 'youtube']" size="2x" style="color: white" class="socialIcon"></fa-icon>
                </a>
                <a href="mailto:info@parsidance.be" target="_blank">
                    <fa-icon [icon]="['fas', 'envelope']" size="2x" style="color: white" class="socialIcon"></fa-icon> 
                </a>
            </div>
        </div>
    
        <div class="openingHours">
            <h2 class="openingHours">OPENING HOURS</h2>
            <p class="openingHours" (click)="redirectToHiddenGame()">
                Fri 18.04  &nbsp;| 16u - 00u<br>
                Sat 19.04 | 13u - 01u
            </p>
        </div>
    
        <div class="location">
            <h2 class="location">LOCATION</h2>
            <a href="https://maps.app.goo.gl/8fyRQEL5KXkYRTbk9" target="_blank" class="location">
                <p class="location">
                    Koestraat 20<br>
                    8800 Rumbeke
                </p>
            </a>
        </div>
    
        <div class="legalLinks">
            <h2 class="legalLinks">LEGAL LINKS</h2>
            <p class="legalLinks">
                <a routerLink="/verkoopsvoorwaarden" class="legalLinks">Conditions of Sale</a><br>
                <a routerLink="/privacypolicy" class="legalLinks">Privacy Policy</a><br>
            </p>
        </div>
    </div>
</footer>