import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ApiService } from './api.service'; 
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { LineupComponent } from './lineup/lineup.component';
import { AzLineupComponent } from './lineup/az/az.component';
import { FridayLineupComponent } from './lineup/friday/friday.component';
import { SaturdayLineupComponent } from './lineup/saturday/saturday.component';

import { TimetableComponent } from './timetable/timetable.component';
import { FridayTimetableComponent } from './timetable/friday/friday.component';
import { SaturdayTimetableComponent } from './timetable/saturday/saturday.component';

import { SponsorsComponent } from './sponsors/sponsors.component';
import { AboutComponent } from './about/about.component';

import { HeaderHomepageComponent } from './components/headerHomepage/headerHomepage.component';
import { HeaderComponent } from './components/header/header.component';

import { FooterComponent } from './components/footer/footer.component';

import { NoPageComponent } from './no-page/no-page.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, faEnvelope, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { VerkoopsvoorwaardenComponent } from './verkoopsvoorwaarden/verkoopsvoorwaarden.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { faFacebook, faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

import {CdkAccordionModule} from '@angular/cdk/accordion';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InfoComponent,
    LineupComponent,
    SponsorsComponent,
    AboutComponent,
    HeaderHomepageComponent,
    HeaderComponent,
    FooterComponent,
    VerkoopsvoorwaardenComponent,
    PrivacypolicyComponent,
    AzLineupComponent,
    FridayLineupComponent,
    SaturdayLineupComponent,
    TimetableComponent,
    FridayTimetableComponent,
    SaturdayTimetableComponent,
    NoPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    CdkAccordionModule,
  ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule {   
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faChevronDown,
      faChevronUp,
      faFacebook,
      faInstagram,
      faTiktok,
      faYoutube,
      faEnvelope,
      faBars,
      faXmark,
      faCirclePlay
    );
  }
}
