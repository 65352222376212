import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-no-page',
  templateUrl: './no-page.component.html',
  styleUrl: './no-page.component.scss'
})
export class NoPageComponent {
  constructor(private meta: Meta) {
    meta.addTag({ name: "robots", content: "noindex, nofollow, nosnippet" });
  }
}
