import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = 'https://api.parsidance.be/';  // Flask server URL

  constructor(private http: HttpClient) {}

  getSoldTickets(): Observable<any> {
    return this.http.get(this.apiUrl + "tickets");
  }
}
