import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
// import { SponsorsComponent } from './sponsors/sponsors.component';
// import { LineupComponent } from './lineup/lineup.component';
// import { AzLineupComponent } from './lineup/az/az.component';
// import { FridayLineupComponent } from './lineup/friday/friday.component';
// import { SaturdayLineupComponent } from './lineup/saturday/saturday.component';
// import { FridayTimetableComponent } from './timetable/friday/friday.component';
// import { SaturdayTimetableComponent } from './timetable/saturday/saturday.component';
import { VerkoopsvoorwaardenComponent } from './verkoopsvoorwaarden/verkoopsvoorwaarden.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
// import { TimetableComponent } from './timetable/timetable.component';
import { HiddengameComponent } from './hiddengame/hiddengame.component';
import { NoPageComponent } from './no-page/no-page.component';

const routes: Routes = [
  { 
    path: '', 
    component: HomeComponent 
  },{ 
    path: 'info', 
    component: InfoComponent, 
    data: { title: 'Info' }
  },{ 
    path: 'verkoopsvoorwaarden', 
    component: VerkoopsvoorwaardenComponent, 
    data: { title: 'Verkoopsvoorwaarden' } 
  },{ 
    path: 'privacypolicy', 
    component: PrivacypolicyComponent, 
    data: { title: 'Privacypolicy' } 
  },{ 
    path: 'hiddengame',
    component: HiddengameComponent,
  },{ 
  // path: 'line-up', 
  //   component: LineupComponent,
  //   data: { title: 'Line-up' },
  //   children:[
  //     {
  //       path:'',
  //       redirectTo: 'a-z',
  //       pathMatch: 'full' 
  //     },{
  //       path:'a-z',
  //       component: AzLineupComponent,
  //     },
  //     {
  //       path:'friday',
  //       component: FridayLineupComponent,
  //       data: { title: 'Line-up Friday' },
  //     },
  //     {
  //       path:'saturday',
  //       component: SaturdayLineupComponent,
  //       data: { title: 'Line-up Saturday' },
  //     },
  //   ] 
  // },{ 
  //   path: 'metdankaan', 
  //   component: SponsorsComponent, 
  //   data: { title: 'Met dank aan' } 
  // },{ 
  //   path: 'sponsors', 
  //   component: SponsorsComponent, 
  //   data: { title: 'Met dank aan' } 
  // },{
  //   path: 'timetable', 
  //   component: TimetableComponent, 
  //   data: { title: 'Timetable' },
  //   children:[
  //     {
  //       path:'',
  //       redirectTo: new Date() >= new Date(2025, 3, 19, 7, 0, 0, 0) ? 'saturday' : 'friday',
  //       pathMatch: 'full' 
  //     },{
  //       path:'friday',
  //       component: FridayTimetableComponent,
  //       data: { title: 'Timetable Friday' },
  //     },
  //     {
  //       path:'saturday',
  //       component: SaturdayTimetableComponent,
  //       data: { title: 'Timetable Saturday' },
  //     },
  //   ] 
  // },{
    path: '**', 
    component: NoPageComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
